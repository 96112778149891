<template>
	<div>
		<el-page-header @back="$router.back()" content="订单详情" class="margin-bottom margin-top-xs"></el-page-header>
		<div>
			<el-divider content-position="left">基本信息</el-divider>
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="100px" class="flex flex-wrap">
					<el-form-item label="订单编号">
						<div class="form-item-box">
							{{form.order_no}}
						</div>
					</el-form-item>
					<el-form-item label="订单状态">
						<div class="form-item-box">
							<el-tag type="success" v-if="form.type==2">进行中</el-tag>
							<el-tag type="info" v-if="form.type==1">待付款</el-tag>
							<el-tag type="info" v-if="form.type==3">待使用</el-tag>
							<el-tag type="danger" v-if="form.type==4">已使用</el-tag>
						</div>
					</el-form-item>
					<el-form-item label="下单时间">
						<div class="form-item-box">
							{{form.created_at}}
						</div>
					</el-form-item>
					<el-form-item label="支付状态">
						<div class="form-item-box" v-if="form.pay_status == 1">待支付</div>
						<div class="form-item-box" v-if="form.pay_status == 2">已支付</div>
						<div class="form-item-box" v-if="form.pay_status == -1">取消支付</div>
					</el-form-item>
					<el-form-item label="付款时间">
						<div class="form-item-box">
							{{form.pay_time}}
						</div>
					</el-form-item>
					<el-form-item label="购买件数">
						<div class="form-item-box">{{form.buy_num}}</div>
					</el-form-item>
					<el-form-item label="成团人数">
						<div class="form-item-box">{{form.num}}人</div>
					</el-form-item>
					<el-form-item label="付款总金额">
						<div class="form-item-box">￥{{form.pay_money}}</div>
					</el-form-item>
					<el-form-item label="核销状态">
						<div class="form-item-box">
							<el-tag type="success" v-if="form.check_status == 2">已核销</el-tag>
							<el-tag type="info" v-if="form.check_status == 1">待核销</el-tag>
						</div>
					</el-form-item>
					<el-form-item label="核销时间">
						<div class="form-item-box">
							{{form.check_time}}
						</div>
					</el-form-item>
<!--					<el-form-item label="商品原价">-->
<!--						<div class="form-item-box">-->
<!--							￥{{form.product_price}}-->
<!--						</div>-->
<!--					</el-form-item>-->
<!--					<el-form-item label="拼团价">-->
<!--						<div class="form-item-box">-->
<!--							￥{{form.promotion_price}}-->
<!--						</div>-->
<!--					</el-form-item>-->
					<el-divider content-position="left">商品信息</el-divider>
					<el-form-item label="活动名称">
						<div class="form-item-box">
							￥{{form.title}}
						</div>
					</el-form-item>
					<el-form-item label="商品原价">
						<div class="form-item-box">
							￥{{form.product_price}}
						</div>
					</el-form-item>
					<el-form-item label="拼团价">
						<div class="form-item-box">
							￥{{form.promotion_price}}
						</div>
					</el-form-item>
					<el-form-item label="当前剩余库存">
						<div class="form-item-box">
							￥{{form.stock}}
						</div>
					</el-form-item>
				</el-form>
			</div>

<!--			<el-table :data="tableData" border style="width: 800px">-->
<!--				<el-table-column prop="date" label="活动名称" width="180">-->
<!--				</el-table-column>-->
<!--				<el-table-column prop="name" label="原价" width="180">-->
<!--				</el-table-column>-->
<!--				<el-table-column prop="address" label="拼团价">-->
<!--				</el-table-column>-->
<!--				<el-table-column prop="address" label="当前剩余库存">-->
<!--				</el-table-column>-->
<!--			</el-table>-->
			<el-divider content-position="left">用户详情</el-divider>
			<el-table :data="form.team_user" border >
				<el-table-column prop="nickname" label="购买人" width="180"></el-table-column>
				<el-table-column prop="identity" label="参团身份"></el-table-column>
				<el-table-column prop="phone" label="手机号"></el-table-column>
				<el-table-column prop="order_no" label="订单号"></el-table-column>
				<el-table-column label="订单类型">
					<template slot-scope="scope">
						<div v-if="scope.row.order_type == 1">单独购买</div>
						<div v-if="scope.row.order_type == 2">活动(拼团)购买</div>
					</template>
				</el-table-column>
				<el-table-column prop="buy_num" label="购买数量"></el-table-column>
				<el-table-column  label="支付状态">
					<template slot-scope="scope">
						<div v-if="scope.row.pay_status == 1">待支付</div>
						<div v-if="scope.row.pay_status == 2">已支付</div>
						<div v-if="scope.row.pay_status == '-1'">取消支付</div>
					</template>
				</el-table-column>
				<el-table-column prop="pay_time" label="支付时间"></el-table-column>
				<el-table-column label="订单状态">
					<template slot-scope="scope">
						<div v-if="scope.row.type == 1">待付款</div>
						<div v-if="scope.row.type == 2">进行中</div>
						<div v-if="scope.row.type == 3">待使用</div>
						<div v-if="scope.row.type == 4">已使用</div>
					</template>
				</el-table-column>
				<el-table-column label="核销状态">
					<template slot-scope="scope">
						<div v-if="scope.row.check_status == 2">已核销</div>
						<div v-else>待核销</div>
					</template>
				</el-table-column>
				<el-table-column prop="check_time" label="核销时间"></el-table-column>
				<el-table-column prop="created_at" label="创建时间"></el-table-column>
			</el-table>
		</div>
	</div>
</template>


<script>
	export default {
		data() {
			return {
				form: {},
				tableData: []
			};
		},
		mounted() {
			// 获取当前编辑的会员信息
			if (this.$route.params.id) {
				this.$api.marketing.getOrderDetails({
					id: this.$route.params.id
				}).then(res => {
					console.log(res)
					res.data.gender = Number(res.data.gender)
					this.form = res.data;
				});
			}
		},
		methods: {

		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
</style>
